import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BadgeLabel from 'components/BadgeLabel';
import { BylineList } from 'components/Byline';
import ContentTimestamp from 'components/ContentTimestamp';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import { getItemData } from '../../../utils/packageDataManager';
import { ContentMedia } from '../../../ContentMedia';
import { Headline } from '../../../Headline';


export function StandardItemContent(props) {
  const {
    content,
    itemData,
    columnIndex,
    showLive,
  } = props;

  const isLiveVideo = showLive;
  const {
    isSmallMedia,
    multiMediaSize,
    aspectRatio,
    packageMetadata,
    delimiter,
    hasEyebrow,
    badgeText,
    multiHeadlineSize,
    headline,
    headlineType,
    hasHeadline,
    storyType,
    unibrow,
    showHeadlineOnly,
    oneToOneAspect,
    hasRelatedContent,
    isVideoContent,
    showDek,
    dek,
    showAuthor,
    showTimestamp,
    item,
    getBadgeType,
    multiStoryData,
    headlineUrl,
  } = getItemData(itemData,
    'multiStorylineItemData',
    { content, showLive, columnIndex });

  const dekJsx = (
    <div className="multi-story__dek publico-txt f3 lh-copy fw4">
      {dek}
    </div>
  );
  const authorTimeJsx = (
    <div className="multi-story-main-content">
      {showAuthor && (
        <BylineList
          authors={item?.authors}
        />
      )}
      {showAuthor && showTimestamp && (
        <span className="ma1">
          {delimiter}
        </span>
      )}
      {showTimestamp && (
        <ContentTimestamp
          dateCreated={item?.dateCreated}
          datePublished={item?.datePublished}
          delimiter=", "
        />
      )}
    </div>
  );

  const mediaSection = (customClass) => {
    if (isLiveVideo || itemData.type === 'custom' || (multiMediaSize !== 'none' && itemData?.computedValues?.teaseImage !== null)) {
      return (
        <div className={classNames(
          'media',
          customClass,
          `media-${multiMediaSize}`, {
            'one-to-one-aspect': oneToOneAspect,
            'live-video-container': isLiveVideo,
            isVideoContent,
          },
        )}
        >
          <div className="media-top-spacer mobile-multistory-media" />
          <ContentMedia
            aspectRatio={aspectRatio}
            contentItem={itemData}
            packageMetadata={packageMetadata}
            multiStoryData={multiStoryData}
            isLiveVideo={isLiveVideo}
            uniqueId={customClass}
          />
        </div>
      );
    }
    return null;
  };

  const standardItemContent = (
    <>
      { mediaSection('non-mobile-multistory-media') }
      <div className={classNames(
        'headline-item-container',
        {
          'headline-container-small': multiMediaSize === 'multiSmall',
        },
      )}
      >
        <div className="standard-layout__badge">
          {hasEyebrow && (
            <>
              <div className="badge-top-spacer" />
              <BadgeLabel
                badgeType={getBadgeType()}
                badgeText={badgeText}
                eyebrowText={unibrow?.text}
                url={unibrow?.url?.primary}
              />
            </>
          )}
        </div>
        <div className={classNames({
          'headline-small': multiHeadlineSize === 'small',
          'headline-standard': multiHeadlineSize === 'standard',
          'headline-large': multiHeadlineSize === 'large',
          'headline-xl': multiHeadlineSize === 'extraLarge',
          'headline-xxl': multiHeadlineSize === 'xxl',

        })}
        >
          { /* if not multiSmall and there is eyebrow, use small spacer */
            hasHeadline && multiMediaSize !== 'multiSmall' && hasEyebrow && (
              <div className="headline-top-spacer" />
            )
          }
          { /* if multiStandard media size and no eyebrow, we are below media, use larger spacer
            that goes to zero in mobile since image will go to bottom.  */
            hasHeadline && multiMediaSize === 'multiStandard' && !hasEyebrow && (
              <div className="headline-under-media-spacer" />
            )
          }
          <Headline
            text={headline}
            headlineSize={headlineType}
            storyType={storyType}
            url={headlineUrl}
            showHeadlineOnly={showHeadlineOnly}
            noBottomSpace={!hasRelatedContent}
          />
          {(isSmallMedia && showDek) && (
            <>
              <div className="dek-top-spacer" />
              {dekJsx}
            </>
          )}
          {(isSmallMedia && (showAuthor || showTimestamp)) && (
            <>
              <div className="author-top-spacer" />
              {authorTimeJsx}
            </>
          )}
        </div>
      </div>
      {(!isSmallMedia && showDek) && (
        <>
          <div className="dek-top-spacer" />
          {dekJsx}
        </>
      )}
      {(!isSmallMedia && (showAuthor || showTimestamp)) && (
        <>
          <div className="author-top-spacer" />
          {authorTimeJsx}
        </>
      )}
    </>
  );

  return standardItemContent;
}

StandardItemContent.propTypes = {
  content: PackagePropType.isRequired,
  /* eslint-disable-next-line react/forbid-prop-types */
  itemData: PropTypes.object.isRequired,
  columnIndex: PropTypes.number,
  showLive: PropTypes.bool,
};

StandardItemContent.defaultProps = {
  columnIndex: null,
  showLive: false,
};
