import { parseLayoutTemplate } from './parseLayoutTemplate';
/* Reason:

  In curator, a dropdown field with a default value will show the default value
  but no value will necessarilly be present in the curator metadata.

  This sets those missing values with the concrete default so that child components
  don't have to "know" the default. Also, we have a case where the setting for
  `layoutTemplate` might be invalid, i.e. if selected for 5 items and then the number of
  items are changed to 6, it will still be set to the 5 item layout until the
  curator user choses something besides the default. The correct default
  for 6 items will, however, be showing in Curator. So we also have to validate
  the layoutTemplate and replace it with the correct default if necessary.

  This module could be generalized by loading `definition.json` for most cases,
  and supporting middleware functions for cases like `layoutTemplate`.

  NOTE:
    Work is done in place, no new objects.
    However, `content` is returned for destructuring.
*/

const defaultLayoutTemplates = {
  // keyed by number of columns then number of content items
  2: {
    2: '1-1',
    3: '1-2',
    4: '1-3',
    5: '1-4',
    6: '1-5',
    7: '1-6',
    8: '1-7',
  },
  3: {
    3: '1-1-1',
    4: '1-1-1',
    5: '1-1-1',
    6: '1-1-1',
    7: '1-1-1',
    8: '1-1-1',
    9: '1-1-1',
  },
};

export function setLayoutDefaults(content) {
  if (!content) return null;
  const { metadata, items } = content;
  if (!metadata || !items) return content;

  /* set columns defaults */
  metadata.columns = metadata.columns
    ? Number(metadata.columns)
    : 2;

  /* check if current value is invalid */

  const { columns } = metadata;
  const numItems = items.length;
  const layoutDef = parseLayoutTemplate(metadata);

  /*
    Layout def is invalid if the number of items it requires do not match the current number of
    content items. This happens because curator only sets the metadata value if the user selects
    something besides the default, and the value might still be set from a previous number of
    items. Curator shows the correct (default) value in in this case.
    By deleting the invalid `layoutTemplate` we let the correct default to be set in the following step.
  */
  if (layoutDef && (numItems !== layoutDef.totalItems)) {
    // invalid type, delete
    delete (metadata.layoutTemplate);
  }
  /* If value doesn't exist or was invalid */
  // set default based on number of items and number of columns
  if (!metadata.layoutTemplate) {
    metadata.layoutTemplate = defaultLayoutTemplates?.[columns]?.[numItems];
    if (!metadata.layoutTemplate) {
      console.warn(`MultiStoryline: No default layout for ${columns} columns with ${numItems} content items.`);
    }
  }
  return content;
}

export function setPlaymakerDefaults(content) {
  if (!content) return null;
  const { metadata } = content;
  if (!metadata) return content;

  const { playmakerToggle, showLiveVideoBadge, playmakerBadge } = metadata;
  if (playmakerToggle && showLiveVideoBadge && !playmakerBadge) {
    // default playmakerBadge
    // eslint-disable-next-line no-param-reassign
    content.metadata.playmakerBadge = 'BREAKING';
  }
  if (playmakerToggle && !showLiveVideoBadge && playmakerBadge) {
    // eslint-disable-next-line no-param-reassign
    delete (content.metadata.playmakerBadge);
  }
  return content;
}

/* Top Level Function, calls others. */
export function setPackageDefaults(content) {
  const DEBUG = process.env.NEXT_PUBLIC_MULTISTORY_DEBUG;

  if (!content) return null;

  if (DEBUG) {
    console.log(`(pd.i112).x CONTENT ${content.name}`, { content });
    console.log(`(pd.i114).x received content.metadata ${content.name}`, { ...content.metadata });
  }

  // Processing steps
  //
  setPlaymakerDefaults(content);
  setLayoutDefaults(content);
  //
  /// /

  if (DEBUG) console.log(`(pd.i124).x processed content.metadata ${content.name}`, { ...content.metadata });

  return content;
}
