import React from 'react';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import { LinkIfHref } from 'components/Link';
import TitleLogo from 'components/TitleLogo';
import { StandardLayout } from './StandardLayout';

import './styles.themed.scss';
import { SmallLayout } from './SmallLayout';
import { applyPackageDefaults } from '../applyPackageDefaults';

export function SingleStoryline({ content: rawContent }) {
  if (!rawContent) return null;

  const content = applyPackageDefaults(rawContent);

  const {
    items,
    metadata,
  } = content;

  const packgeTitle = metadata?.packageTitle;
  const packgeTitleUrl = metadata?.packageTitleUrl;
  const logoUrl = metadata?.logoUrl;
  const mediaSize = metadata.mediaSize || 'medium';
  const eyebrowUrl = items[0]?.computedValues?.unibrow?.url?.primary;
  const hasRelateds = items[0]?.related?.length;
  const showSmallLayout = mediaSize === 'small' || (mediaSize === 'none' && hasRelateds);
  return (
    <div className="single-storyline" data-testid="single-storyline">
      { logoUrl && (
        <div className="single-storyline__section-title">
          <LinkIfHref href={packgeTitleUrl || eyebrowUrl}>
            <TitleLogo logoUrl={logoUrl} />
          </LinkIfHref>
        </div>
      )}
      { !logoUrl && packgeTitle && (
        <div className="single-storyline__section-title">
          <LinkIfHref href={packgeTitleUrl || eyebrowUrl}>
            { packgeTitle }
          </LinkIfHref>
        </div>
      )}
      {showSmallLayout ? <SmallLayout content={content} /> : <StandardLayout content={content} />}
    </div>
  );
}

SingleStoryline.propTypes = {
  content: PackagePropType.isRequired,
};
