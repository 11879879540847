import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'components/Link';
import colorLogo from 'assets/icons/color-logo-badge.svg';
import playBtn from 'assets/icons/play-btn-watch-badge.svg';
import { BADGES } from './constants';
import styles from './styles.module.scss';

const BadgeLabel = ({
  badgeText, badgeType, eyebrowText, url, playmakerBadge,
}) => {
  const badgeIsEyebrow = badgeType === BADGES.EYEBROW;
  if (!badgeType || (!eyebrowText && badgeIsEyebrow)) {
    return null;
  }
  const {
    FIRST_ON_NBC, BREAKING, LIVE, DEVELOPING, EXCLUSIVE, SPECIAL_REPORT, WATCH, EYEBROW, DATA_GRAPHIC,
  } = BADGES;

  const displayBadgeType = badgeType.toUpperCase();
  const displayBadgeText = () => {
    if (displayBadgeType === EYEBROW) {
      return eyebrowText.toUpperCase();
    }
    return (
      typeof badgeText === 'string'
      && badgeText.trim() !== ''
      && !Object.values(displayBadgeType).includes(badgeText)
    ) ? badgeText.toUpperCase() : displayBadgeType;
  };
  const redBadge = [BREAKING, LIVE, DEVELOPING];
  const blackBadge = [FIRST_ON_NBC];
  const yellowBadge = [EXCLUSIVE, SPECIAL_REPORT, DATA_GRAPHIC];
  const badgeStyles = classNames({
    [styles.iconPosition]: badgeType === LIVE,
    [styles.badgeLabel]: !playmakerBadge,
    [styles.playmakerBadgeLabel]: playmakerBadge,
    [styles.blackBadge]: blackBadge.includes(displayBadgeType),
    [styles.redBadge]: redBadge.includes(displayBadgeType),
    [styles.yellowBadge]: yellowBadge.includes(displayBadgeType),
    [styles.watchBadge]: displayBadgeType === WATCH,
  });

  const iconDisplay = (type) => {
    let displayIcon;
    switch (type) {
      case FIRST_ON_NBC:
        displayIcon = colorLogo;
        break;
      case WATCH:
        displayIcon = playBtn;
        break;
      default:
        displayIcon = null;
    }
    return (
      displayIcon && (
        <img className={styles.icon} alt={displayBadgeText()} src={displayIcon} />
      )
    );
  };

  const badgeDisplay = () => (
    <span data-testid={`badge-label-${badgeType}`} className={badgeStyles}>
      {iconDisplay(displayBadgeType)}
      {displayBadgeText()}
    </span>
  );

  const playmakerBadgeDisplay = () => {
    if (badgeType === LIVE) {
      return (
        <span data-testid="badge-label-storyline-live" className={badgeStyles}>
          {iconDisplay(displayBadgeType)}
          <span className={styles.inlineBlinkingDot} />
          {displayBadgeText()}
        </span>
      );
    }

    return (
      <span data-testid={`badge-label-${badgeType}`} className={badgeStyles}>
        {iconDisplay(displayBadgeType)}
        {displayBadgeText()}
      </span>
    );
  };

  const badgeContent = playmakerBadge ? playmakerBadgeDisplay() : badgeDisplay();
  const hasUrlAndEyeBrow = url && badgeIsEyebrow;
  return hasUrlAndEyeBrow ? <Link to={url}>{badgeContent}</Link> : <>{badgeContent}</>;
};

BadgeLabel.propTypes = {
  badgeType: PropTypes.string,
  badgeText: PropTypes.string,
  eyebrowText: PropTypes.string,
  url: PropTypes.string,
  playmakerBadge: PropTypes.bool,
};

BadgeLabel.defaultProps = {
  badgeType: null,
  badgeText: null,
  eyebrowText: null,
  url: null,
  playmakerBadge: false,
};

export default BadgeLabel;
