import React from 'react';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import loadScript from 'lib/loadScript';
import PropTypes from 'prop-types';
import './styles.themed.scss';

const DataVizLoader = ({ data, uniqueId }) => {
  const { id, computedValues: { url: embedUrl } } = data;
  const embedID = uniqueId ? `embed-${id}-${uniqueId}` : `embed-${id}`;

  React.useEffect(() => {
    if (embedUrl) {
      loadScript('https://nodeassets.nbcnews.com/cdnassets/pym/1.3.2/pym.min.js')
        .then(() => {
          if (window.pym) {
            // Strip query string params from url that might have been copied from development.
            const cleanedEmbedUrl = embedUrl.replace(/&(childId|parentUrl)=[^&]+/g, '');
            // Pym adds iframe and establishes communication for controlling height.
            window[embedID] = new window.pym.Parent(embedID, cleanedEmbedUrl);
          }
        }).catch((err) => console.error('Data Viz Error', err));
    }
  }, [embedID]);

  return <div className="data-viz-div" id={embedID} />;
};

DataVizLoader.propTypes = {
  data: ContentPropType.isRequired,
  uniqueId: PropTypes.string,
};

DataVizLoader.defaultProps = {
  uniqueId: null,
};

export default DataVizLoader;
