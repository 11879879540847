import React from 'react';
import { Gate } from 'components/Gate';
import { GATE_TITLES } from 'components/Gate/GateTextConstants';

import styles from './styles.module.scss';

const letters = ['A', 'E', 'M', 'L', 'D', 'S', 'C', 'S', 'P', 'T', 'D', 'S', 'T', 'A', 'U', 'P'];


export function SignInPrompt() {
  return (
    <div className={styles.container} data-testid="word-search-game--sign-in-prompt">
      <div className={styles.overlay}>
        <div className={styles.letters}>
          {letters.map((letter, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={index}>{letter}</span>
          ))}
        </div>
      </div>
      <Gate title={GATE_TITLES.game} />
    </div>
  );
}
