import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { socialMediaProfiles as socialMediaProfilesPropTypes } from 'lib/CustomPropTypes';
import { attribution } from 'lib/social';
import { stub as $t } from '@nbcnews/analytics-framework';


const mapStateToProps = ({ shared }) => ({
  socialMediaProfiles: shared.socialMediaProfiles,
});

class SocialShare extends React.Component {
  static propTypes = {
    eventName: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    socialMediaProfiles: socialMediaProfilesPropTypes.isRequired,
    url: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { eventName } = this.props;
    $t('register', eventName);
  }

  track = (platform) => {
    const { eventName } = this.props;
    $t('track', eventName, { platform });
  }

  render() {
    const {
      headline,
      socialMediaProfiles,
      url,
    } = this.props;

    const shareAttrib = attribution(socialMediaProfiles);

    return (
      <>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
          onClick={() => { this.track('facebook'); }}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="icon icon-facebook" data-testid="social-icon-facebook" />
        </a>
        <a
          // eslint-disable-next-line max-len
          href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(headline)}&via=${shareAttrib}&url=${url}&original_referer=${url}`}
          onClick={() => { this.track('twitter'); }}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="icon icon-twitter" data-testid="social-icon-twitter" />
        </a>
        <a
          href={`mailto:?subject=${headline}&body=${url}`}
          onClick={() => { this.track('email'); }}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="icon icon-email" data-testid="social-icon-email" />
        </a>
      </>
    );
  }
}

export default connect(mapStateToProps)(SocialShare);
