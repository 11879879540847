import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import Link from 'components/Link';
import TypeIcon from 'components/TypeIcon';
import TeasePicture from 'components/TeasePicture';
import { Unibrow } from 'components/Unibrow';
import { Save } from 'components/SocialShareMenu/Save';
import { TypeIconInline } from 'components/TypeIconInline';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { timeFrom } from 'lib/DateTime';
import { imageAltForItem } from 'lib/imageAlt';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import { getSiteConfigs } from 'lib/vertical';
import { getUniformTeaseItem, isThinkModule } from 'lib/teaseUtils';
import { isBreakingNewsText } from 'lib/breakingNews';
import { LayoutContext, VerticalContext } from 'lib/ContextTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { PKG_SAVE, AUTHOR_FEED_TEASE_VARIANT } from 'lib/brandFeatures';

import './styles.themed.scss';
import RecipeDetails from 'components/Recipe/RecipeDetails';

const POSSIBLE_METADATA = {
  TEASE: 'showTeaseImage',
  TIMESTAMP: 'showTimestamp',
  DEK: 'showDek',
  EYEBROW: 'showEyebrow',
  BRAND: 'showBrand',
};

const renderArticleImage = ({
  showImage, tease, additionalClasses, content, url, isFluidWidthPage, showSave, vertical,
}) => {
  const {
    type,
    id,
  } = content;
  const teaseVariant = getFeatureConfigForBrand(AUTHOR_FEED_TEASE_VARIANT, vertical);
  const squareTease = teaseVariant || isFluidWidthPage;

  if (!showImage || !tease) {
    return showSave ? (
      <div
        className={classNames('wide-tease-item__no-image-save', additionalClasses)}
        data-testid="wide-tease-save"
      >
        <Save
          contentId={id}
          contentType={type}
        />
      </div>
    ) : null;
  }

  return (
    <div
      className={classNames(
        'wide-tease-item__image-wrapper',
        'flex-none',
        'relative',
        additionalClasses,
      )}
    >
      <Link
        to={url}
        data-testid="wide-tease-image"
      >
        <TeasePicture
          className="wide-tease-item__image"
          type={type}
          placeholder={false}
          retina
          responsiveFlavors={{
            s: squareTease ? AIMS_FLAVORS.FOCAL_200x200 : AIMS_FLAVORS.FOCAL_200x100,
            m: AIMS_FLAVORS.FOCAL_220x110,
            l: AIMS_FLAVORS.FOCAL_280x140,
            xl: AIMS_FLAVORS.FOCAL_260x130,
          }}
          url={tease}
          alt={imageAltForItem(content)}
        />
        {showSave ? (
          <Save
            contentId={id}
            contentType={type}
            options={{
              isThumbnail: true,
              offset: 's-only',
            }}
            additionalClasses="wide-tease-item__save"
          />
        ) : (
          <TypeIcon
            type={type}
            className="wide-tease-item__type-icon"
          />
        )}
      </Link>
    </div>
  );
};

const getBrand = ({
  vertical, showBrand, additionalClasses, isFluidWidthPage,
}) => {
  if (!showBrand || !vertical) {
    return null;
  }

  const brandUnibrand = {
    text: vertical,
  };

  const config = getSiteConfigs(vertical.toLowerCase());
  if (config?.siteUrl) {
    brandUnibrand.url = {
      primary: config.siteUrl,
    };
  }

  const unibrowClassNames = !isFluidWidthPage ? classNames(
    additionalClasses,
    'wide-tease-item__unibrow',
    'wide-tease-item__unibrow--brand',
  ) : additionalClasses;

  return (
    <Unibrow
      className={unibrowClassNames}
      hasDefaultTextStyle={isFluidWidthPage}
      unibrow={brandUnibrand}
    />
  );
};

const getUnibrow = ({
  additionalClasses,
  content,
  showEyebrow,
  unibrow,
  isFluidWidthPage,
}) => {
  if (!showEyebrow || !unibrow) {
    return null;
  }

  const moduleClass = isThinkModule(content);

  const unibrowClassNames = !isFluidWidthPage ? classNames(
    moduleClass,
    additionalClasses,
    'wide-tease-item__unibrow',
  ) : additionalClasses;

  return (
    <Unibrow
      className={unibrowClassNames}
      hasDefaultTextStyle={isFluidWidthPage}
      unibrow={unibrow}
    />
  );
};

const renderUnibrow = ({
  additionalClasses,
  content,
  dataAttributes,
  showBrand,
  showEyebrow,
  unibrow,
  vertical,
  isFluidWidthPage,
}) => {
  const unibrowComp = getUnibrow({
    additionalClasses,
    content,
    showEyebrow,
    unibrow,
    isFluidWidthPage,
  });
  const brandComp = getBrand({
    vertical, showBrand, additionalClasses, isFluidWidthPage,
  });

  if (!unibrowComp && !brandComp) {
    return null;
  }

  const separator = unibrowComp && brandComp ? (
    <div
      className={classNames(
        'wide-tease-item__unibrow-separator',
        additionalClasses,
      )}
    >
      {' '}
      /
      {' '}
    </div>
  ) : (
    ''
  );

  // if breaking news, return vanilla unibrow without brand & separator
  if (isBreakingNewsText(unibrow.text)) {
    return unibrowComp;
  }

  return (
    <div
      className="wide-tease-item__unibrow-wrapper df"
      data-testid={dataAttributes}
    >
      {brandComp}
      {separator}
      {unibrowComp}
    </div>
  );
};

const renderUnibrowAndDate = ({
  content,
  datePublished,
  showBrand,
  showEyebrow,
  showTimestamp,
  unibrow,
  vertical,
  isFluidWidthPage,
}) => {
  const { t } = useTranslation();

  const hasDate = showTimestamp && !!datePublished;
  const unibrowComps = renderUnibrow({
    additionalClasses: 'dib dn-m',
    content,
    showBrand,
    showEyebrow,
    unibrow,
    vertical,
    isFluidWidthPage,
  });
  const hasUnibrow = !!unibrowComps;

  const showEyebrowWrapper = hasUnibrow || hasDate;
  if (!showEyebrowWrapper) {
    return null;
  }

  return (
    <div
      className={
        classNames(
          'wide-tease-item__unibrow-timestamp',
          'df',
          'flex-none-m',
          {
            'dn-m': !hasDate && hasUnibrow,
          },
        )
      }
    >
      {unibrowComps}
      {hasDate && (
        <div
          className={classNames('wide-tease-item__timestamp dib db-m',
            {
              ml3: hasUnibrow,
              'ml0-m': hasUnibrow,
            })}
          data-testid="wide-tease-date"
        >
          {`${t('time_ago', { time: timeFrom(datePublished) })}`}
        </div>
      )}
    </div>
  );
};

const getMetadata = (path, props) => {
  const {
    content: {
      metadata,
    },
  } = props;
  return get(metadata, path, true);
};

function WideTease(props) {
  const {
    isRail,
    isLastArticle,
    hideBorder,
    content,
  } = props;

  const { isFluidWidthPage, isRailLayout } = useContext(LayoutContext);
  const pageVertical = useContext(VerticalContext);
  const unifiedItem = getUniformTeaseItem(content);

  const { item } = content;
  const {
    datePublished,
    description,
    headline,
    tease,
    unibrow,
    vertical,
    url,
  } = unifiedItem;

  const showDek = getMetadata(POSSIBLE_METADATA.DEK, props);
  const showTimestamp = getMetadata(POSSIBLE_METADATA.TIMESTAMP, props);
  const showBrand = getMetadata(POSSIBLE_METADATA.BRAND, props);
  const showEyebrow = getMetadata(POSSIBLE_METADATA.EYEBROW, props);
  const showImage = getMetadata(POSSIBLE_METADATA.TEASE, props);
  const hasImage = showImage && !!tease;
  const showSave = getFeatureConfigForBrand(PKG_SAVE, pageVertical);

  return (
    <div
      className={classNames(
        'wide-tease-item__wrapper',
        'df',
        'flex-column',
        'flex-row-m',
        'flex-nowrap-m',
        {
          'wide-tease-item__wrapper--last-page': isLastArticle,
          'wide-tease-item__wrapper--no-border': hideBorder,
          'wide-tease-item__wrapper--full-width': !isRailLayout,
        },
      )}
      data-contentid={content.id}
      data-testid="wide-tease"
      key={content.id}
    >
      {renderUnibrowAndDate({
        content,
        datePublished,
        showBrand,
        showEyebrow,
        showTimestamp,
        unibrow,
        vertical,
        isFluidWidthPage,
      })}
      <div
        className={classNames(
          'wide-tease-item__info-wrapper',
          'flex-grow-1-m',
          {
            'wide-tease-item__info-wrapper--no-art': !hasImage && !isRail,
          },
        )}
        data-testid="wide-tease-info-wrapper"
      >
        {renderUnibrow({
          additionalClasses: 'dn db-m',
          content,
          dataAttributes: 'wide-tease-unibrow',
          showBrand,
          showEyebrow,
          unibrow,
          vertical,
          isFluidWidthPage,
        })}
        <Link
          to={url}
        >
          <h2
            className="wide-tease-item__headline"
            data-testid="wide-tease-headline"
          >
            {isFluidWidthPage && (
              <TypeIconInline type={content.type} taxonomy={content?.item?.taxonomy} />
            )}
            {headline}
          </h2>
        </Link>
        <div className={classNames({ df: hasImage || isFluidWidthPage })}>
          {showDek && (
            <div
              className="wide-tease-item__description"
              data-testid="wide-tease-dek"
            >
              {description}
            </div>
          )}
          {renderArticleImage({
            tease, showImage, additionalClasses: 'dt dn-m', content, url, isFluidWidthPage, showSave, vertical,
          })}
        </div>
        <RecipeDetails
          type={content.type}
          cookTime={item?.cookTime}
          prepTime={item?.prepTime}
          servingSize={item?.servingSize}
          yields={item?.yield}
        />
      </div>

      {renderArticleImage({
        tease, showImage, additionalClasses: 'dn dt-m', content, url, isFluidWidthPage, showSave,
      })}
    </div>
  );
}

WideTease.propTypes = {
  content: PackagePropType.isRequired,
  hideBorder: PropTypes.bool,
  isLastArticle: PropTypes.bool,
  isRail: PropTypes.bool,
};

WideTease.defaultProps = {
  hideBorder: false,
  isLastArticle: false,
  isRail: false,
};

export default WideTease;
