import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { waffleCategory } from 'lib/CustomPropTypes';
import styles from './styles.module.scss';


/**
 * Filter component for filtering options based on user selections.
* @class
* @extends React.Component
* @param { object } props - The properties passed to the component.
* @param { string } props.categoryName - The accordion name.
* @param { object } props.categoryToDisplay - Object containing category names to display.
* @param { array } [props.categoryValues = []] - Array of category values.
* @param { boolean } [props.enableMultiselectFilters = true] - ability to select multiple fitlers at once.
* @param { function} [props.onChange = () => { }] - Function called when filter options change.
* @param { object } [props.value = {}] - Object containing selected filter values, can be used to pre - select the open filter.
*
* @returns { React.Component } - The Filter component.
* ---------------
* the checked behavior and styling are handled by CSS logic so as to not have to load up on state for each filter
* label: has(input: checked) is the sole logic used to visually update the 'checked' state styling
* labels are used as the clickable element / wrapper
* keyboard - nav will skip the label and tab through the input elements instead
*
* the visual indicator is just a visual representation of the checked state as the input element purposely isn't visible.
*
* there two open - filter - state managers, props.isOpen and state.mobileAccordionOpen
* on desktop, the selected open accordion is managed by the parent component via the isOpen prop.
* on mobile, the open state is set per accordion is mangaed by state.mobileAccordionOpen
* this is so that the click - outside - to - dismiss behavior is consistent with having only one open accordion
 */

class Filter extends Component {
  static propTypes = {
    categoryName: PropTypes.string.isRequired,
    categoryToDisplay: PropTypes.objectOf(PropTypes.string).isRequired,
    categoryValues: PropTypes.arrayOf(waffleCategory),
    enableMultiselectFilters: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.objectOf(PropTypes.bool),
    dismissOverlay: PropTypes.func,
    setDesktopActiveAccordion: PropTypes.func,
    isSorM: PropTypes.bool,
    isOpen: PropTypes.bool,
    clearActiveAccordion: PropTypes.func,
    onFilterClose: PropTypes.func,
    onlyOneFilter: PropTypes.bool,
  };

  static defaultProps = {
    categoryValues: [],
    enableMultiselectFilters: true,
    value: {},
    isSorM: false,
    isOpen: false,
    onChange: () => { },
    dismissOverlay: () => { },
    setDesktopActiveAccordion: () => { },
    clearActiveAccordion: () => { },
    onFilterClose: () => { },
    onlyOneFilter: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      mobileAccordionOpen: false,
    };
    this.filterContainerRef = createRef();
  }

  componentDidMount() {
    const { isOpen } = this.props;
    if (isOpen) {
      this.attachAccordionListeners();
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen, isSorM, onlyOneFilter } = this.props;
    const { mobileAccordionOpen } = this.state;
    if (isOpen && isOpen !== prevProps.isOpen) {
      // handles when the component initially renders in an open state and needs event handling.
      this.attachAccordionListeners();
    }
    if (isSorM && onlyOneFilter && !mobileAccordionOpen) {
      // this handles the cases where an accordion should be expanded on open.
      this.openAccordion();
    }
  }

  componentWillUnmount() {
    this.removeAccordionListeners();
  }

  handleClickOutside = (e) => {
    const { current } = this.filterContainerRef;
    const { isSorM, onFilterClose } = this.props;

    if (isSorM // click outside only for desktop
      || !e // if no event data
      || !current // if no ref
      || current.contains(e.target) // if the click is within the ref element
    ) return;

    onFilterClose();
    this.closeAccordion();
  };

  handleEscapeKey = (e) => {
    if (e.key === 'Escape') {
      const { onFilterClose } = this.props;
      onFilterClose();
      this.closeAccordion();
    }
  };

  handleInputChange = (e) => {
    const { value, checked } = e.target;
    const { onChange, isSorM } = this.props;
    onChange(value, checked);
    if (!isSorM) {
      this.closeAccordion();
    }
  };

  openAccordion = () => {
    const { setDesktopActiveAccordion, categoryName, isSorM } = this.props;
    setDesktopActiveAccordion(categoryName);
    if (isSorM) {
      this.setState({ mobileAccordionOpen: true });
    } else {
      this.attachAccordionListeners();
    }
  };

  attachAccordionListeners = () => {
    window.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('keydown', this.handleEscapeKey);
  };

  removeAccordionListeners = () => {
    window.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('keydown', this.handleEscapeKey);
  };

  closeAccordion = () => {
    const { isSorM, clearActiveAccordion, dismissOverlay } = this.props;

    if (isSorM) {
      dismissOverlay();
      this.setState({ mobileAccordionOpen: false });
    } else {
      this.removeAccordionListeners();
      clearActiveAccordion();
    }
  };

  renderNewAccordion = (filterName) => {
    const {
      categoryValues,
      categoryName,
      value,
      enableMultiselectFilters,
    } = this.props;

    return (
      <fieldset className={classNames(styles.accordion, styles.open)} data-testid={`accordion_${filterName}`}>
        <legend>{filterName}</legend>
        <button
          className={classNames(styles.openTitle)}
          onClick={this.closeAccordion}
          type="button"
          aria-label={`Collapse ${filterName} filter`}
          data-testid={`openTitle_${filterName}`}
        >
          {filterName}
          <span aria-hidden="true" className={classNames(styles.arrow, 'icon icon-angle-down')} />
        </button>
        <div className={styles.optionsContainer}>
          {categoryValues.map(({ key, name, active }, i) => (
            key && name && (
              <div
                className={classNames(styles.option, { [styles.disabled]: !active })}
                key={key}
                data-testid={`option_${name}`}
              >
                <label
                  className={styles.label}
                  htmlFor={`input_${name}`}
                  data-testid={`label_${filterName}${i}`}
                >
                  <span
                    className={classNames(styles.visualIndicator, {
                      [styles.checkbox]: enableMultiselectFilters,
                      [styles.radio]: !enableMultiselectFilters,
                    })}
                    aria-hidden="true"
                    data-testid={`visualIndicator_${filterName}${i}`}
                  />
                  <input
                    checked={value[key] || false}
                    disabled={!active}
                    id={`input_${name}`}
                    name={categoryName}
                    onChange={this.handleInputChange}
                    type={enableMultiselectFilters ? 'checkbox' : 'radio'}
                    value={key}
                    data-testid={`input_${filterName}${i}_${enableMultiselectFilters ? 'checkbox' : 'radio'}`}
                  />
                  {name}
                </label>
              </div>
            )
          ))}
        </div>
      </fieldset>
    );
  };

  render() {
    const {
      categoryValues,
      enableMultiselectFilters,
      categoryToDisplay,
      categoryName,
      isOpen,
      isSorM,
    } = this.props;
    const { mobileAccordionOpen } = this.state;

    const accordionOpen = isSorM ? mobileAccordionOpen : isOpen;

    if (!categoryValues.length) return null;

    const filterName = categoryToDisplay[categoryName];

    return (
      <div className={styles.filterContainer} ref={this.filterContainerRef}>
        {!accordionOpen && (
          <button
            className={classNames(styles.closedTitle, styles.visible)}
            onClick={this.openAccordion}
            type="button"
            aria-label={`Expand ${filterName} filter`}
            data-testid={`closedTitle_${filterName}`}
          >
            {filterName}
            <span aria-hidden="true" className={classNames(styles.arrow, 'icon icon-angle-down', styles.visible)} />
          </button>
        )}
        <div
          className={classNames(styles.filterWrapper, {
            [styles.open]: accordionOpen,
            [styles.closed]: !accordionOpen,
            [styles.multiple]: enableMultiselectFilters,
          })}
        >
          {accordionOpen && this.renderNewAccordion(filterName)}
        </div>
      </div>
    );
  }
}

export { Filter };
