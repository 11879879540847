import React from 'react';
import PropTypes from 'prop-types';

import { useIsGeoRestricted } from 'components/OmegaVideoPlayer/useIsGeoRestricted';
import { GeoRestrictionSlate } from 'components/OmegaVideoPlayer/GeoRestrictionSlate';

/**
 * GeoRestriction restricts content based on the user's geolocation.
 * It uses a cookie (ng_geolocation) to determine the user's region.
 * If the user's region is not in the allowed regions, it displays a slate image with a message.
 * If the user's region is allowed, it renders the children passed to it.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.Node} props.children - The children to be rendered if the user's region is allowed.
 * @returns {React.Element} The GeoRestriction component.
 */
export function GeoRestriction({ children }) {
  return useIsGeoRestricted() ? <GeoRestrictionSlate /> : children;
}

GeoRestriction.propTypes = {
  children: PropTypes.node.isRequired,
};
