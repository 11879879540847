import React from 'react';
import classNames from 'classnames';
import { content as ItemPropType } from 'lib/CustomPropTypes';
import { useFeatureFlagContext } from 'lib/ContextTypes';
import { LinkIfHref } from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import VideoPlayer from 'components/VideoPlayer';
import PropTypes from 'prop-types';
import BadgeLabel from 'components/BadgeLabel';
import './styles.themed.scss';
import PlaymakerTease from 'components/PlaymakerTease';
import DataVizLoader from 'components/DataVizLoader';


const picture = (item, size) => {
  const IMAGE_TRANSFORMATIONS = {
    small: {
      s: AIMS_FLAVORS.FOCAL_762x508,
      m: AIMS_FLAVORS.FOCAL_321x214,
      l: AIMS_FLAVORS.FOCAL_412x275,
      xl: AIMS_FLAVORS.FOCAL_500x333,
    },
    large: {
      s: AIMS_FLAVORS.FOCAL_762x508,
      m: AIMS_FLAVORS.FOCAL_412x275,
      l: AIMS_FLAVORS.FOCAL_500x333,
      xl: AIMS_FLAVORS.FOCAL_1000x667,
    },
    default: {
      s: AIMS_FLAVORS.FOCAL_762x508,
      m: AIMS_FLAVORS.FOCAL_412x275,
      l: AIMS_FLAVORS.FOCAL_500x333,
      xl: AIMS_FLAVORS.FOCAL_1000x667,
    },
  };

  // make seperate config for multi

  return (
    <LinkIfHref href={item.computedValues.url}>
      <TeasePicture
        responsiveFlavors={IMAGE_TRANSFORMATIONS[size] || IMAGE_TRANSFORMATIONS.default}
        type={item.type}
        computedValues={item.computedValues}
        hideCredit={size === 'small'}
      />
    </LinkIfHref>
  );
};

const liveVideoPlayer = (packageMetadata) => (
  <PlaymakerTease
    icid="storyline-tease"
    metadata={packageMetadata}
    showDek={false}
    showTitle
    showPlayerControls
    isStoryline
  />
);

/**
 *
 * @param {object} item video content object with metadata
 * @param {boolean} isMutedAutoplayEnabled whether the sle-autoplay flag is enabled
 * @param {object} packageMetadata metadata provided by curator outside of the scope of the item
 *
 * @returns the corresponding inline video component with props set based off of curator metadata
 */
const inlineVideoPlayer = (item, isMutedAutoplayEnabled) => {
  const { item: video, computedValues = {}, metadata } = item;
  const { teaseImage = {} } = computedValues;

  const {
    sublineBadge,
    sublineBadgeText,
    showSublineBadge = false,
    playmakerAutoplayMuted,
    playmakerPlayBehavior,
  } = metadata || {};
  const autoPlay = isMutedAutoplayEnabled ? playmakerPlayBehavior === 'autoplay' : false;
  return (
    <>
      <VideoPlayer
        autoPlay={autoPlay}
        hideVideoTitle
        isEmbedded
        replay
        disableSticky
        onlyAutoplayMuted={playmakerAutoplayMuted ?? true}
        video={{ ...video, teaseImage }}
      />
      {
        metadata.videoSubHeadline?.length
        && (
          <div className="video-subheadline">
            <span className="video-subline-link">
              {showSublineBadge && (
                <div className="video-subline-badge">
                  <BadgeLabel
                    badgeType={sublineBadge}
                    badgeText={sublineBadgeText}
                  />
                </div>
              )}
              <span className="video-subheadline-text">
                {metadata.videoSubHeadline}
              </span>
            </span>
          </div>
        )
      }
    </>
  );
};

export function StorylineMedia({
  contentItem, packageMetadata = {}, isLiveBlogLayout,
}) {
  const { 'sle-autoplay-muted': isMutedAutoplayEnabled } = useFeatureFlagContext();
  if (!contentItem) return null;
  const {
    playmakerToggle = false,
  } = packageMetadata;
  // need to look at how we are checking each package

  const {
    mediaSize: size = 'medium',
  } = packageMetadata;

  const isVideo = contentItem.type === 'video' || playmakerToggle === true;
  const isDataViz = contentItem.type === 'custom';
  const mediaIsImage = () => !(playmakerToggle || isVideo || isDataViz);

  const getMedia = () => {
    // *****************
    // NEED TO LOOK AT THIS. Moved contentItem.type check to top
    // *****************

    if (playmakerToggle) {
      return liveVideoPlayer(packageMetadata);
    }
    if (contentItem.type === 'video') return inlineVideoPlayer(contentItem, isMutedAutoplayEnabled);
    if (isDataViz) return <DataVizLoader data={contentItem} />;
    return picture(contentItem, size);
  };

  const media = getMedia();
  return (
    <div
      className={classNames(
        'storyline-media',
        {
          'data-viz': isDataViz,
          'media-small': size === 'small' && !isVideo,
          'media-medium': size === 'medium' && !isVideo,
          'media-large': size === 'large' && !isVideo,
          'image-ratio': mediaIsImage(),
          'liveblog-layoutMedia': isLiveBlogLayout,
          'storyline-small-video': size === 'small' && isVideo,
          'storyline-medium-video': size === 'medium' && isVideo,
          'storyline-large-video': size === 'large' && isVideo,
          'live-video': playmakerToggle,
        },
      )}
      data-testid={(playmakerToggle ? 'storyline-media-live' : `storyline-media-${contentItem.type}`)}
    >
      {media}
    </div>
  );
}

StorylineMedia.propTypes = {
  contentItem: ItemPropType.isRequired,
  packageMetadata: PropTypes.object,
  isLiveBlogLayout: PropTypes.bool,
};

StorylineMedia.defaultProps = {
  packageMetadata: {},
  isLiveBlogLayout: false,
};
