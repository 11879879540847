import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { LinkIfHref } from 'components/Link';

import { TypeIconInline } from 'components/TypeIconInline';
import RecipeDetails from 'components/Recipe/RecipeDetails';
import styles from './styles.module.scss';

function Headline(props) {
  const {
    headline,
    metadata,
    nativeAd,
    product,
    sponsorName,
    url,
    headlineClass,
    item,
    type,
    isFluidWidthPage,
  } = props;
  const headlineIsLinkable = !!(url);
  const headlineIsNotLinkable = !headlineIsLinkable;
  const headlineClasses = classNames(
    headlineClass,
    styles.headline,
    'waffle__headline',
    {
      [styles.product]: product,
      [styles.white]: nativeAd,
      [styles.disabled]: headlineIsNotLinkable,
    },
  );

  const cookTime = item?.cookTime;
  const prepTime = item?.prepTime;
  const servingSize = item?.servingSize;
  const yields = item?.yield;

  return (
    <LinkIfHref
      className="db"
      href={url}
      target={metadata.openInNewTab ? '_blank' : '_self'}
    >
      <h2 className={headlineClasses} data-test="waffle-headline" data-testid="waffle-headline">
        {isFluidWidthPage ? <TypeIconInline type={type} /> : null }
        {headline}
      </h2>
      <RecipeDetails
        type={type}
        cookTime={cookTime}
        prepTime={prepTime}
        servingSize={servingSize}
        yields={yields}
      />
      {(nativeAd && sponsorName) && (
        <p className={styles.sponsor} data-test="waffle-headline__sponsor">
          {sponsorName}
        </p>
      )}
    </LinkIfHref>
  );
}

Headline.propTypes = {
  headline: PropTypes.string.isRequired,
  metadata: PropTypes.shape({
    openInNewTab: PropTypes.bool,
  }),
  nativeAd: PropTypes.bool,
  product: PropTypes.bool,
  isFluidWidthPage: PropTypes.bool,
  sponsorName: PropTypes.string,
  url: PropTypes.string,
  headlineClass: PropTypes.string,
  type: PropTypes.string,
  item: PropTypes.shape({
    cookTime: PropTypes.string,
    prepTime: PropTypes.string,
    servingSize: PropTypes.string,
    yield: PropTypes.string,
  }),
};

Headline.defaultProps = {
  metadata: {
    openInNewTab: false,
  },
  nativeAd: false,
  product: false,
  isFluidWidthPage: false,
  sponsorName: '',
  url: null,
  headlineClass: '',
  type: '',
  item: {},
};

export { Headline };
