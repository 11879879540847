import React from 'react';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import { LinkIfHref } from 'components/Link';
import classNames from 'classnames';
import { TwoColumn } from './TwoColumn/index';
import { parseLayoutTemplate } from '../utils/parseLayoutTemplate';
import './styles.themed.scss';
import { PackageTitleTopBorder } from '../../../PackageTitleTopBorder/index';

export function MultiStorylineContainer({ content }) {
  if (!content) return null;
  const {
    items,
    metadata,
  } = content;

  if (!items || !metadata) return null;

  const {
    packageTitle,
    packageTitleUrl,
    packageGoBigStyle,
    leadColumn,
    leadColumnStackFirstOnMobile,
    showBottomBorder = true,
    playmakerToggle,
    playmakerColumn = 'Column1',
    showVerticalBorderA = true,
    column1Title,
    column2Title,
  } = metadata;

  const hasColumn1Lead = leadColumn === 'Column1Lead';
  const hasColumn2Lead = leadColumn === 'Column2Lead';


  // Get Layout Info
  // note: parseLayoutTemplate is looking for layoutTemplate but can depend on other metadata if needed.
  const layoutDef = parseLayoutTemplate(metadata);
  if (!layoutDef) {
    // eslint-disable-next-line no-console
    console.error('MultiStoryline ERROR: Invalid Layout');
    return null;
  }
  const { itemsPerColumn } = layoutDef;

  const column1items = items.slice(0, itemsPerColumn[0]);
  const column2items = items.slice(itemsPerColumn[0], itemsPerColumn[0] + itemsPerColumn[1]);

  const itemsByColumn = [column1items, column2items];
  let currentItemIndex = 0;

  const columnsJsx = itemsByColumn.reduce((ac, itemList, columnIndex) => {
    const columnNumber = columnIndex + 1;
    const columnName = `Column${columnNumber}`;
    const showLive = playmakerToggle && columnName === playmakerColumn;
    const isLeadColumn = leadColumn === `${columnName}Lead`;
    const isNotLeadColumn = leadColumn && !isLeadColumn;
    // const noLeadColumn = !leadColumn;
    const innerColumnJoin = columnIndex === 0;

    /** check to see if leadcolumn stack first on mobile -> if yes, apply border to the lead column.
    If not, apply border to the 0th column (it is always at the top if toggle is not on) * */
    const showBottomColumnBorderMobile = leadColumnStackFirstOnMobile ? isLeadColumn : innerColumnJoin;

    const currentColumnTitle = columnIndex === 0 ? column1Title : column2Title;

    const itemEl = (
      <div
        key={`${columnName}`}
        className={classNames(
          'multistory-items-column',
          {
            'show-vertical-border-1': showVerticalBorderA,
            'lead-column': isLeadColumn,
            'not-lead-column': isNotLeadColumn,
            'before-inner-vertical-join': innerColumnJoin,
            'show-bottom-border-mobile': showBottomColumnBorderMobile,
          },
        )}
      >
        <div className="column-title">
          <PackageTitleTopBorder
            title={currentColumnTitle}
          />
        </div>
        {
          itemList.map((itemData, index) => {
            const innerJoin = index < itemList.length - 1;
            const showBottomContentBorder = innerJoin;
            const twoColumnJsx = itemData && (
              <TwoColumn
                key={`${itemData.id}-${columnName}`}
                content={content}
                itemData={itemData}
                showLive={showLive && index === 0}
                index={columnIndex}
                itemIndex={currentItemIndex}
                showVerticalBorder={showVerticalBorderA}
                showBottomBorder={showBottomContentBorder}
                addBottomSpace={innerJoin}
              />
            );
            // the currentItemIndex has to be calculated because
            // it's needed when the content item is a live blog.
            // <LiveBlog> gets the item from package data itself.
            currentItemIndex += 1;
            return twoColumnJsx;
          })
        }
      </div>
    );
    ac.push(itemEl);
    if (showVerticalBorderA && columnIndex === 0) {
      ac.push((
        <div
          className={classNames(
            'columnSeparator',
            `columnSeparator-${columnIndex + 1}`,
          )}
          key="verticalBorderA"
        />
      ));
    }
    return ac;
  }, []);

  return (
    <div
      className={classNames(
        'multi-storyline-container',
        { 'package-go-big': packageGoBigStyle },
      )}
      data-testid="multi-storyline-container"
    >
      { packageTitle && (
        <div className="multi-storyline-container__package-title founders-cond">
          <LinkIfHref href={packageTitleUrl}>
            { packageTitle }
          </LinkIfHref>
        </div>
      )}
      <div className={classNames(
        'column-container',
        {
          'column-1-lead': hasColumn1Lead,
          'column-2-lead': hasColumn2Lead,
          'lead-column-stack-first-on-mobile': leadColumnStackFirstOnMobile,
        },
      )}
      >
        { columnsJsx }
      </div>
      { showBottomBorder && (<hr className="pkg-bottom-border" />)}
    </div>
  );
}

MultiStorylineContainer.propTypes = {
  content: PackagePropType.isRequired,
};
