import React from 'react';

import { package as PackagePropType } from 'lib/CustomPropTypes';

import { MultiStorylineContainer } from './MultiStorylineContainer';
import { setPackageDefaults } from './utils/packageDefaults';

import './styles.themed.scss';

export function MultiStoryline({ content }) {
  if (!content) return null;
  const {
    id,
    items,
    metadata,
  } = setPackageDefaults(content);

  if (!items || !items.length || !metadata) return null;

  return (
    <section
      data-packageid={id}
      className="pkg multi-storyline"
    >
      <MultiStorylineContainer content={content} />
    </section>
  );
}

MultiStoryline.propTypes = {
  content: PackagePropType.isRequired,
};
