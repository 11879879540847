import React from 'react';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import { LinkIfHref } from 'components/Link';
import classNames from 'classnames';
import { TwoColumn } from './TwoColumn/index';
import { parseLayoutTemplate } from '../utils/parseLayoutTemplate';
import './styles.themed.scss';

export function MultiStorylineContainer({ content }) {
  if (!content) return null;
  const {
    items,
    metadata,
  } = content;

  if (!items || !metadata) return null;

  const {
    packageTitle,
    packageTitleUrl,
    packageGoBigStyle,
    leadColumn,
    showBottomBorder = true,
    playmakerToggle,
    playmakerColumn,
    showVerticalBorderA = true,
    showVerticalBorderB = true,
    columns,
  } = metadata;
  const hasColumn1Lead = leadColumn === 'Column1Lead';
  const hasColumn2Lead = leadColumn === 'Column2Lead';
  const hasColumn3Lead = leadColumn === 'Column3Lead';
  const hasLeadColumn = hasColumn1Lead || hasColumn2Lead || hasColumn3Lead;

  // Get Layout Info
  // note: parseLayoutTemplate is looking for layoutTemplate but can depend on other metadata if needed.
  const layoutDef = parseLayoutTemplate(metadata);

  if (!layoutDef) {
    /* eslint-disable-next-line no-console */
    console.error('MultiStoryline ERROR: Invalid Layout');
    return null;
  }
  const { itemsPerColumn } = layoutDef;

  const itemsCopy = [...items];
  const itemsByColumn = itemsPerColumn.reduce((ac, numItemsInColumn, index) => {
    for (let i = 0; i < numItemsInColumn; i += 1) {
      const colItems = ac[index] || [];
      const item = itemsCopy.shift();
      colItems.push(item);
      // eslint-disable-next-line no-param-reassign
      ac[index] = colItems;
    }
    return ac;
  }, []);

  // LiveBlog has to be told its content item index.
  // <LiveBlog> gets the item from package data itself
  // at the given itemIndex. It's not the loop index b/c that's the
  // item position in that column.
  let currentItemIndex = 0;

  const columnsJsx = itemsByColumn.reduce((ac, itemList, columnIndex) => {
    const columnNumber = columnIndex + 1;
    const columnName = `Column${columnNumber}`;
    const columnClass = `items-column-${columnNumber}`;
    const itemsCountClass = `items-count-class-${itemList.length < 3 ? itemList.length : '3plus'}`;
    const showLive = playmakerToggle && columnName === playmakerColumn;
    const isLeadColumn = leadColumn === `${columnName}Lead`;
    const isNotLeadColumn = leadColumn && !isLeadColumn;
    // const innerColumnJoin
    //         = columnIndex === 0 || (columns === 3 && columnIndex===1);
    // const showBottomColumnBorderMobile
    //         = isLeadColumn || innerColumnJoin;
    const innerColumnJoin = columnNumber < columns;
    const showBottomColumnBorderMobile = innerColumnJoin;
    const showBothVerticalBorders = (showVerticalBorderA && showVerticalBorderB);
    const showNoVerticalBorders = (!showVerticalBorderA && !showVerticalBorderB);
    const showOneVerticalBorder = !showBothVerticalBorders && !showNoVerticalBorders;
    const itemEl = (
      <div
        key={`${columnName}`}
        className={classNames(
          'multistory-items-column',
          columnClass,
          itemsCountClass, // needed for column-3 rearrange
          {
            'two-column': columns === 2,
            'three-column': columns === 3,
            'show-vertical-border-1': showVerticalBorderA,
            'show-vertical-border-2': showVerticalBorderB,
            'both-vertical-borders': showBothVerticalBorders,
            'one-vertical-border': showOneVerticalBorder,
            'lead-column': isLeadColumn,
            'not-lead-column': isNotLeadColumn,
            'has-lead-column': hasLeadColumn,
            'no-lead-column': !hasLeadColumn,
            'before-inner-vertical-join': innerColumnJoin,
            'show-bottom-column-border-mobile': showBottomColumnBorderMobile,
          },
        )}
      >
        {
          itemList.reduce((jsxList, itemData, index) => {
            const innerJoin = index < itemList.length - 1;
            const showBottomContentBorder = innerJoin;

            const twoColumnJsx = itemData && (
              <TwoColumn
                className={classNames(
                  {
                    'row-mode-860': hasLeadColumn && columnNumber === 3,
                  },
                )}
                key={`${itemData.id}-${columnName}`}
                content={content}
                itemData={itemData}
                showLive={showLive && index === 0}
                index={columnIndex}
                itemIndex={currentItemIndex}
                showVerticalBorder={showVerticalBorderA}
                showBottomBorder={showBottomContentBorder}
                addBottomSpace={innerJoin}
              />
            );
            // the currentItemIndex has to be tracked because
            // it's needed when the content item is a live blog.
            // <LiveBlog> gets the item from package data itself
            // at the given itemIndex.
            currentItemIndex += 1;

            // add separator
            jsxList.push(twoColumnJsx);
            const endOfRow = (index % 3) === 2;
            if (endOfRow && innerJoin) {
              jsxList.push((
                <div className="row-mode-horizontal-divider" />
              ));
            }
            return jsxList;
          }, [])
        }
      </div>
    );
    ac.push(itemEl);
    const insertSepA = (showVerticalBorderA && columnIndex === 0);
    const insertSepB = (showVerticalBorderB && columnIndex === 1);
    const insertSep = insertSepA || insertSepB;
    if (insertSep) {
      const columnSepName = `columnSeparator-${columnIndex + 1}`;
      ac.push((
        <div
          key={columnSepName}
          className={classNames(
            'columnSeparator',
            columnSepName,
          )}
        />
      ));
    }
    return ac;
  }, []);

  return (
    <div
      className={classNames(
        'multistory-container',
        {
          'two-column': columns === 2,
          'three-column': columns === 3,
          'has-lead-column': hasLeadColumn,
          'no-lead-column': !hasLeadColumn,
          'package-go-big': packageGoBigStyle,
        },
      )}
      data-testid="multi-storyline-container"
    >
      { packageTitle && (
        <div className="multi-storyline-container__package-title founders-cond">
          <LinkIfHref href={packageTitleUrl}>
            { packageTitle }
          </LinkIfHref>
        </div>
      )}
      <div className={classNames(
        'column-container',
        {
          'two-column': columns === 2,
          'three-column': columns === 3,
          'has-lead-column': hasLeadColumn,
          'no-lead-column': !hasLeadColumn,
          'column-1-lead': hasColumn1Lead,
          'column-2-lead': hasColumn2Lead,
          'column-3-lead': hasColumn3Lead,
          'vertical-divider-A': showVerticalBorderA,
          'vertical-divider-B': showVerticalBorderB,
        },
      )}
      >
        { columnsJsx }
      </div>
      { showBottomBorder && (<hr className="pkg-bottom-border" />)}
    </div>
  );
}

MultiStorylineContainer.propTypes = {
  content: PackagePropType.isRequired,
};
