import React from 'react';
import PropTypes from 'prop-types';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import './styles.themed.scss';
import classNames from 'classnames';
import { LiveBlogContent } from './ItemContent/LiveBlogContent';
import { StandardItemContent } from './ItemContent/StandardItemContent';
import { StandardItemRelatedContent } from './ItemContent/StandardItemRelatedContent';
import { getItemData } from '../../utils/packageDataManager';

export function TwoColumn({
  className,
  content,
  itemIndex,
  itemData,
  index, // this is actually columnIndex
  showVerticalBorder,
  showLive,
  addBottomSpace,
  showBottomBorder,
}) {
  if (!content || !itemData) return null;
  const columnIndex = index;

  const {
    hasRelatedContent,
    multiMediaSize,
    isLiveBlog,
    itemType,
    colLead,
    leadColumn,
  } = getItemData(
    itemData,
    'multiStorylineItemData',
    { content, showLive, columnIndex },
  );

  return (
    <div className={classNames(
      'multistory-item',
      `item-type-${itemType}`,
      {
        [className]: className,
        'multi-storyline-item': content,
        'show-vertical-border': showVerticalBorder,
        'image-lead': colLead,
        'not-image-lead': !colLead && leadColumn !== 'none',
        'lead-column': colLead,
        'not-lead-column': !colLead && leadColumn !== 'none',
        'add-bottom-space': addBottomSpace,
        'show-bottom-border': showBottomBorder,
      },
    )}
    >
      <div className={classNames(
        'multi-item-container', {
          'multi-small-container': multiMediaSize === 'multiSmall',
          [`contained-media-${multiMediaSize}`]: true,
        },
      )}
      >
        { isLiveBlog
          ? (
            <LiveBlogContent
              content={content}
              itemIndex={itemIndex}
              itemData={itemData}
              columnIndex={columnIndex}
              showLive={showLive}
            />
          )
          : (
            <StandardItemContent
              content={content}
              itemIndex={itemIndex}
              itemData={itemData}
              showLive={showLive}
            />
          )}
      </div>
      {!isLiveBlog && hasRelatedContent && (
        <>
          <div className="multistory-related-spacer" />
          <StandardItemRelatedContent
            content={content}
            itemIndex={itemIndex}
            itemData={itemData}
            showLive={showLive}
          />
        </>
      )}
    </div>
  );
}

TwoColumn.propTypes = {
  content: PackagePropType.isRequired,
  /* eslint-disable-next-line react/forbid-prop-types */
  itemData: PropTypes.object.isRequired,
  className: PropTypes.string,
  index: PropTypes.number,
  showVerticalBorder: PropTypes.bool,
  showLive: PropTypes.bool,
  addBottomSpace: PropTypes.bool,
  itemIndex: PropTypes.number,
  showBottomBorder: PropTypes.bool,
};

TwoColumn.defaultProps = {
  index: null,
  showVerticalBorder: false,
  showLive: false,
  addBottomSpace: false,
  itemIndex: null,
  showBottomBorder: false,
  className: '',
};
