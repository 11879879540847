import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Link from 'components/Link';
import { content as contentPropType } from 'lib/CustomPropTypes';
import { getNestedProperties } from 'lib/nestedPropertiesUtil';
import { VerticalContext } from 'lib/ContextTypes';
import { isBefore } from 'lib/DateTime';
import { getLinkAttribs } from 'lib/getLiveBlogEmbeddedLink';
import { removeRichTextMarkup } from 'lib/richMarkupUtils';
import { ChatByline } from './ChatByline/index';
import { ChatAvatar } from './ChatAvatar/index';
import { Eyebrow } from './Eyebrow/index';

import './styles.themed.scss';

const ONE_MINUTE = 60000; // Milliseconds
// lbp = live-blog-pkg

function Card({
  content,
  isBreaking,
  isEmbedded,
  isWebEmbed,
  showTimestamp,
  liveBlogStyle,
}) {
  const isCurationPackage = !!content.computedValues;
  const autofilled = content?.autofilled ?? true;
  const isPinnedPost = !autofilled;
  const isChatStyle = liveBlogStyle === 'chatStyle';
  const isReportedStyle = liveBlogStyle === 'reportedStyle';
  const isStorylineChatStyle = liveBlogStyle === 'storylineChatStyle';

  let headline;
  let url;
  let pinnedLabel;
  let publishedAt;
  if (isCurationPackage) {
    headline = content?.computedValues?.headline;
    url = content?.computedValues?.url;
    pinnedLabel = content?.metadata?.pinnedLabel;
    publishedAt = content?.item?.date?.publishedAt ?? {};
  } else {
    headline = (content?.headline?.tease ?? null)
      || (content?.headline?.primary)
      || (content?.headline?.social);
    url = content?.url?.primary;
    publishedAt = content?.date?.publishedAt ?? {};
  }

  const getFirstGrafText = (cardContent) => {
    const path = cardContent?.item?.content?.markupAndEmbeds ? 'item.content.markupAndEmbeds' : 'content.markupAndEmbeds';
    return (getNestedProperties(cardContent, path) ?? []).find((item) => item.element === 'p')?.html;
  };
  let firstGraf = null;
  let authors = [];

  if (isChatStyle || isStorylineChatStyle) {
    authors = content.item?.authors;
    firstGraf = getFirstGrafText(content);
  }

  if (isChatStyle) {
    const isHeadlineHidden = (content?.isHeadlineHidden) || Boolean(!headline);
    headline = (isHeadlineHidden && firstGraf?.length) ? removeRichTextMarkup(firstGraf) : headline;
  }

  if (isStorylineChatStyle) {
    firstGraf = firstGraf?.length ? removeRichTextMarkup(firstGraf) : null;
  }

  const {
    id,
    authors: bentoApiAuthors,
  } = content;
  const vertical = React.useContext(VerticalContext);
  const { href, deviceUrl, target } = getLinkAttribs(url, id, isEmbedded, isWebEmbed, vertical);
  const renderAuthors = authors || bentoApiAuthors;

  return (
    <li
      data-test="lbp-card"
      data-testid="lbp-card"
      data-contentid={id}
      className={classNames(
        'lbp-card',
        {
          'lbp-card--breaking': isBreaking,
          'lbp-card--animate-in': isBefore(publishedAt, ONE_MINUTE),
          'lbp-card--large': !showTimestamp,
          [`lbp-card--${liveBlogStyle}`]: liveBlogStyle,
          mb1: liveBlogStyle === 'storylineChatStyle' && !firstGraf,
        },
      )}
    >
      {
        // Must send href as null since Link component uses deviceUrl as separate fallback prop
        /* eslint-disable jsx-a11y/anchor-is-valid */
      }
      <Link
        className={classNames(
          'lbp-card__link',
          {
            [`lbp-card__link--${liveBlogStyle}`]: liveBlogStyle,
          },
        )}
        data-test="lbp-card__link"
        data-testid="lbp-card__link"
        href={deviceUrl ? null : href} // use deviceUrl if embedded
        target={target}
        deviceUrl={deviceUrl}
      >
        <div>
          <span className={classNames(
            'lbp-card__meta-line',
            {
              [`lbp-card__meta-line--${liveBlogStyle}`]: liveBlogStyle,
              isBreaking,
            },
          )}
          >
            {isChatStyle && (
              <ChatByline
                authors={renderAuthors}
                liveBlogStyle={liveBlogStyle}
                vertical={vertical}
                isChatStyle
              />
            )}
            {isStorylineChatStyle && (
              <ChatAvatar
                authors={renderAuthors}
                liveBlogStyle={liveBlogStyle}
                vertical={vertical}
                isChatStyle
              />
            )}
            {(isChatStyle || isReportedStyle) && (
              <Eyebrow
                isBreaking={isBreaking}
                isPinnedPost={isPinnedPost}
                pinnedLabel={pinnedLabel}
                showTimestamp={showTimestamp}
                publishedAt={publishedAt}
              />
            )}
          </span>
          <h5
            className={classNames(
              'lbp-card__headline',
              {
                [`lbp-card__headline--${liveBlogStyle}`]: liveBlogStyle,
                'di pr1': isStorylineChatStyle,
                'lbp-card__trunc': liveBlogStyle !== 'storylineChatStyle',
              },
            )}
            data-test="lbp-card__headline"
            data-testid="lbp-card__headline"
          >
            {headline}
          </h5>
          {isStorylineChatStyle && (
            <>
              <Eyebrow
                isBreaking={isBreaking}
                isPinnedPost={isPinnedPost}
                pinnedLabel={pinnedLabel}
                showTimestamp={showTimestamp}
                publishedAt={publishedAt}
                isInline
              />
              {firstGraf && (
                <p className="lbp-card__trunc lbp-card__graf ma0 mt2 f3 lh-copy" data-testid="lbp-card__graf">{firstGraf}</p>
              )}
            </>
          )}
        </div>
        {isChatStyle && (
          <div
            data-testid="lbp-icon-chevron-right"
            className="icon icon-chevron-right"
          />
        )}
      </Link>
      {
        /* eslint-enable jsx-a11y/anchor-is-valid */
      }
    </li>
  );
}

Card.propTypes = {
  content: contentPropType.isRequired,
  isBreaking: PropTypes.bool,
  isEmbedded: PropTypes.bool,
  isWebEmbed: PropTypes.bool,
  showTimestamp: PropTypes.bool,
  liveBlogStyle: PropTypes.oneOf(['chatStyle', 'reportedStyle', 'storylineChatStyle']),
};

Card.defaultProps = {
  isBreaking: false,
  isEmbedded: false,
  isWebEmbed: false,
  showTimestamp: true,
  liveBlogStyle: 'reportedStyle',
};

export default Card;
