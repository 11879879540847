import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'components/Button/';

import { package as PackagePropType } from 'lib/CustomPropTypes';
import { useMyNewsStore } from 'store';
import { useGeoLocation, useTracking } from 'lib/Hooks';
import { Picture } from 'components/Picture';
import AIMS_FLAVORS from 'lib/aimsFlavors';

import { INITIALIZED, UNAUTHENTICATED } from 'lib/myNewsConstants';
import styles from './styles.module.scss';

/**
 * Creates AccountPromo package. Intended to prompt users to sign up for an account.
 * @param {PackagePropType} content package data
 * @param {string} pkgClassName Additional classnames to provide to the package
 */
const AccountPromo = ({ content, pkgClassName }) => {
  const authenticationState = useMyNewsStore((state) => state.authenticationState);
  const authenticate = useMyNewsStore((state) => state.authenticate);
  const { isUsa } = useGeoLocation();
  const track = useTracking('hfs_identity_packageSignupClick');

  const shouldShowPackage = (authenticationState === INITIALIZED || authenticationState === UNAUTHENTICATED) && isUsa;
  if (!shouldShowPackage) return null;

  const {
    metadata: {
      promoImage, text, title, id: packageId,
    },
  } = content;

  const classes = classNames(
    pkgClassName,
    styles.section,
  );

  return (
    <section
      className={classNames(classes)}
      data-testid="account-promo"
      data-activity-map="account-promo-package"
      data-packageid={packageId}
    >
      <div className={
        classNames(
          styles.container,
          'grid-col-push-1-m grid-col-10-m',
        )
      }
      >
        <div className={styles.left}>
          <h2 className={styles.title}>{title}</h2>
          <Button
            onClick={() => {
              track();
              authenticate();
            }}
            title={text}
            tiltClasses={styles.button}
          />
        </div>
        <div className={styles.right}>
          <Picture
            url={promoImage}
            alt="Promo Package"
            className="promoPackagePicture"
            flavor="fit"
            lazyloaded
            placeholder
            responsiveFlavors={{
              s: AIMS_FLAVORS.FIT_760w,
              m: AIMS_FLAVORS.FIT_660w,
              l: AIMS_FLAVORS.FIT_760w,
              xl: AIMS_FLAVORS.FIT_1000w,
            }}
          />
        </div>
      </div>
    </section>
  );
};

AccountPromo.propTypes = {
  content: PackagePropType.isRequired,
  pkgClassName: PropTypes.string,
};

AccountPromo.defaultProps = {
  pkgClassName: 'pkg accountPromo',
};

export default AccountPromo;
