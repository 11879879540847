import React from 'react';
import classNames from 'classnames';
import { content as ItemPropType } from 'lib/CustomPropTypes';
import { LinkIfHref } from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import VideoPlayer from 'components/VideoPlayer';
import PropTypes from 'prop-types';
import BadgeLabel from 'components/BadgeLabel';
import PlaymakerTease from 'components/PlaymakerTease';
import DataVizLoader from 'components/DataVizLoader';
import contentMediaStyles from './styles.module.scss';


const picture = (item, size) => {
  const IMAGE_TRANSFORMATIONS = {
    oneToOne: {
      s: AIMS_FLAVORS.FOCAL_200x200,
      m: AIMS_FLAVORS.FOCAL_200x200,
      l: AIMS_FLAVORS.FOCAL_200x200,
      xl: AIMS_FLAVORS.FOCAL_200x200,
    },
    small: {
      s: AIMS_FLAVORS.FOCAL_762x508,
      m: AIMS_FLAVORS.FOCAL_321x214,
      l: AIMS_FLAVORS.FOCAL_412x275,
      xl: AIMS_FLAVORS.FOCAL_500x333,
    },
    large: {
      s: AIMS_FLAVORS.FOCAL_762x508,
      m: AIMS_FLAVORS.FOCAL_412x275,
      l: AIMS_FLAVORS.FOCAL_500x333,
      xl: AIMS_FLAVORS.FOCAL_1000x667,
    },
    multiSmall: {
      s: AIMS_FLAVORS.FOCAL_762x508,
      m: AIMS_FLAVORS.FOCAL_321x214,
      l: AIMS_FLAVORS.FOCAL_300x210,
      xl: AIMS_FLAVORS.FOCAL_350x250,
    },
    multiStandard: {
      s: AIMS_FLAVORS.FOCAL_762x508,
      m: AIMS_FLAVORS.FOCAL_339x225,
      l: AIMS_FLAVORS.FOCAL_380x250,
      xl: AIMS_FLAVORS.FOCAL_410x290,
    },
    default: {
      s: AIMS_FLAVORS.FOCAL_762x508,
      m: AIMS_FLAVORS.FOCAL_412x275,
      l: AIMS_FLAVORS.FOCAL_500x333,
      xl: AIMS_FLAVORS.FOCAL_1000x667,
    },
  };

  // make seperate config for multi

  return (
    <LinkIfHref href={item.computedValues.url}>
      <TeasePicture
        className="multiStoryPicture"
        responsiveFlavors={IMAGE_TRANSFORMATIONS[size] || IMAGE_TRANSFORMATIONS.default}
        type={item.type}
        computedValues={item.computedValues}
        hideCredit={size === 'multiSmall'}
      />
    </LinkIfHref>
  );
};


const liveVideoPlayer = (packageMetadata) => (
  <PlaymakerTease
    icid="storyline-tease"
    metadata={packageMetadata}
    showDek={false}
    showTitle
    showPlayerControls
    isStoryline
  />
);

const inlineVideoPlayer = (item) => {
  const { item: video, computedValues = {}, metadata } = item;
  const { teaseImage = {} } = computedValues;

  const {
    sublineBadge,
    sublineBadgeText,
    showSublineBadge = false,
  } = metadata || {};

  return (
    <>
      <VideoPlayer
        hideVideoTitle
        isEmbedded
        replay
        disableSticky
        video={{ ...video, teaseImage }}
      />
      {
        metadata.videoSubHeadline?.length
        && (
          <div className={contentMediaStyles['video-subheadline']}>
            <span className={contentMediaStyles['video-subline-link']}>
              {showSublineBadge && (
                <div className={contentMediaStyles['video-subline-badge']}>
                  <BadgeLabel
                    badgeType={sublineBadge}
                    badgeText={sublineBadgeText}
                  />
                </div>
              )}
              <span className={contentMediaStyles['video-subheadline-text']}>
                {metadata.videoSubHeadline}
              </span>
            </span>
          </div>
        )
      }
    </>
  );
};

export function ContentMedia({
  contentItem,
  packageMetadata = {},
  isLiveBlogLayout,
  multiStoryData,
  aspectRatio,
  isLiveVideo,
  uniqueId,
}) {
  if (!contentItem) return null;
  // only respecting known aspect ratios
  const aspectOneToOne = aspectRatio === '1 / 1';

  const {
    playmakerToggle = false,
  } = packageMetadata;

  let {
    mediaSize: size = 'medium',
  } = packageMetadata;

  // const isLiveVideo = multiStoryData?.isLiveVideo;

  const isVideo = contentItem.type === 'video' || playmakerToggle === true;
  const isDataViz = contentItem.type === 'custom';

  if (multiStoryData.isMulti === true) {
    size = multiStoryData.size;
  }

  const mediaIsImage = () => !(playmakerToggle || isVideo);

  const getMedia = () => {
    // *****************
    // NEED TO LOOK AT THIS. Moved contentItem.type check to top
    // *****************
    size = (aspectOneToOne) ? 'oneToOne' : size;
    if (multiStoryData?.isMulti === true) {
      if (isLiveVideo === true) {
        const packageMetadataWithToggle = packageMetadata;
        // @@CHECK: don't think this is needed
        packageMetadataWithToggle.playmakerToggle = true;
        return liveVideoPlayer(packageMetadataWithToggle);
      }
      if (isDataViz) return <DataVizLoader data={contentItem} uniqueId={uniqueId} />;
      if (contentItem.type === 'video' && contentItem.metadata.mediaSize !== 'multiSmall') return inlineVideoPlayer(contentItem);
      return picture(contentItem, size);
    }

    if (playmakerToggle) {
      return liveVideoPlayer(packageMetadata);
    }
    if (contentItem.type === 'video' && !isLiveVideo) return inlineVideoPlayer(contentItem);
    return picture(contentItem, size);
  };

  const media = getMedia();
  return (
    <div
      className={classNames(
        contentMediaStyles['multistory-media'],
        {
          [contentMediaStyles['aspect-one-to-one']]: aspectOneToOne,
          [contentMediaStyles['storyline-media']]: !isLiveVideo,
          [contentMediaStyles['media-small']]: size === 'small' && !isVideo,
          [contentMediaStyles['media-medium']]: size === 'medium' && !isVideo,
          [contentMediaStyles['media-large']]: size === 'large' && !isVideo,
          [contentMediaStyles['multistory-small']]: size === 'multiSmall' && !isVideo,
          [contentMediaStyles['multistory-standard']]: size === 'multiStandard' && !isVideo,
          [contentMediaStyles['multistory-standard-video']]: size === 'multiStandard' && isVideo,
          [contentMediaStyles['multistory-small-video']]: size === 'multiSmall' && isVideo,
          [contentMediaStyles['image-ratio']]: mediaIsImage(),
          [contentMediaStyles['liveblog-layoutMedia']]: isLiveBlogLayout,
          [contentMediaStyles['storyline-small-video']]: size === 'small' && isVideo,
          [contentMediaStyles['storyline-medium-video']]: size === 'medium' && isVideo,
          [contentMediaStyles['storyline-large-video']]: size === 'large' && isVideo,
          [contentMediaStyles['live-video']]: playmakerToggle || isLiveVideo,
        },
      )}
      data-testid={(playmakerToggle ? 'storyline-media-live' : `storyline-media-${contentItem.type}`)}
    >
      {media}
    </div>
  );
}

ContentMedia.propTypes = {
  contentItem: ItemPropType.isRequired,
  packageMetadata: PropTypes.object,
  isLiveBlogLayout: PropTypes.bool,
  multiStoryData: PropTypes.object,
  isLiveVideo: PropTypes.bool,
  aspectRatio: PropTypes.string,
  uniqueId: PropTypes.string,
};

ContentMedia.defaultProps = {
  packageMetadata: {},
  isLiveBlogLayout: false,
  multiStoryData: {},
  isLiveVideo: false,
  aspectRatio: '3 / 2',
  uniqueId: null,
};
